import { defineStore } from 'pinia'

export const useEnum = defineStore({
  id: 'enum',
  state: () => ({
    enums: [],
  }),
  getters: {
    isEnumLoaded: state => state.enums.length > 0,
    isEnumEmpty: state => state.enums.length === 0,
    getEnum: state => enumName => state.enums.find(e => e.name === enumName),
    getEnumValue: state => enumName => {
      const enumData = state.enums.find(e => e.name === enumName)
      
      return enumData ? enumData.value : {}
    },
  },
  actions: {
    async fetchEnumByName(enumName, isPrivate=false) {
      const enumData = this.getEnumValue(enumName)

      if (Object.keys(enumData).length > 0){
        return enumData
      }

      const url= (isPrivate ? '/enum/' : '/public/enum/') + enumName
      const { data: result } = await useApi(url)

      this.enums.push({
        name: enumName,
        value: result.value,
      })
      
      return this.getEnumValue(enumName)
    },
  },
  computed: {
    getEnumValue: state => enumName => {
      const enumData = state.enums.find(e => e.name === enumName)
            
      return enumData ? enumData : null
    },
  },
})
