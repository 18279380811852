<script setup>
import CapaCalender from "@/views/incident/capa-calender/index.vue"

definePage({
  meta: {
    name: "capa-calender",

    // subject: "dashboard.incident",
    // action: "read",
  },
})
</script>

<template>
  <CapaCalender />
</template>
