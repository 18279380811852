<script setup>
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import pages401 from '@images/pages/401.png'
import miscMaskDark from '@images/pages/misc-mask-dark.png'
import miscMaskLight from '@images/pages/misc-mask-light.png'
import logout from '@/services/logout'  

definePage({
  alias: '/pages/misc/not-authorized',
  meta: {
    layout: 'blank',
    public: true,
  },
})

const authThemeMask = useGenerateImageVariant(miscMaskLight, miscMaskDark)
</script>

<template>
  <div class="misc-wrapper">
    <ErrorHeader
      status-code="401"
      title="You are not authorized! 🔐"
      description="You do not have permission to view this page using the credentials that you have provided while login.
       Please contact your site administrator."
    />

    <div class="mt-2 mb-10 d-flex gap-6">
      <VBtn to="/">
        Back to Home
      </VBtn>

      <VBtn @click="logout">
        <VIcon
          class="mr-2"
          icon="tabler-logout"
        />
        Logout
      </VBtn>
    </div>

    <!-- 👉 Image -->
    <div class="misc-avatar w-100 text-center">
      <VImg
        :src="pages401"
        alt="Coming Soon"
        :max-width="170"
        class="mx-auto"
      />
    </div>

    <VImg
      :src="authThemeMask"
      class="misc-footer-img d-none d-md-block"
    />
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/misc.scss";
</style>
