<script setup>
import { userAuthState } from '@/@core/auth'
import { MODULES } from '@/@core/utils/helpers'
import { ability } from '@/plugins/casl/ability'
import loginBg from '@images/pages/login-bg.jpg'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { useRoute, useRouter } from 'vue-router'

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const route = useRoute()
const router = useRouter()

const form = ref({
  employee_code: '',
  password: '',
  remember: false,
})

const errors = ref({
  employee_code: null,
  password: null,
})

const loading = ref(false)

const loginForm = ref()
const isPasswordVisible = ref(false)

const authThemeMask =loginBg

// submit form
const onSubmit = () => {
  loginForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid)
      loading.value = true
    login()
  })
}

const login = async() => {
  await userAuthState().login(form.value).
    then(res => {
    // redirect to home
      if(!res.status) {
      // check if the error is a string or an object
        
        errors.value = res.errors
      
        return
      }
  
      // ability.update(res?.ability || [])
      // if (res.ability) {
      //   let abl = res.ability.map(permi => {
      //     return {
      //       action: 'view', // or specific actions like 'view', 'edit'
      //       subject: `${permi.subject}.${permi.action}` // e.g., 'dashboard', 'widget.total_feedbacks'
      //     }
      //   })
      //   console.log('WHILE LOGIN')
      //   ability.update(abl)
      // }
      

      // TODO: Simplify & Move redirection logic in guard.js
      if (
        route.query.to &&
        route.query.to?.split("/")?.[2] &&
        res.modules.find(x => x.name.toLowerCase() === route.query.to.split("/")[2])
      ) {
        router.replace(MODULES.find(x => x.name.toLowerCase() === route.query.to.split("/")[2]).to)
      } else if (res.modules.length === 1) {
        const module = MODULES.find(x => x.name === res.modules[0].name)

        router.replace(module.to)
      } else {
        router.replace('/app/panel')
      }
      
    }).catch(error => {
      console.error('login error', error)
    })

  setTimeout(() => {
    loading.value = false
  }, 1500)
}
</script>

<template>
  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <VImg
          class="auth-footer-mask"
          :src="authThemeMask"
        />
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-4"
      >
        <VCardText>
          <VNodeRenderer
            :nodes="themeConfig.app.client.logo"
            class="mb-6"
          />
          <h4 class="text-h4 mb-1">
            <span class="text-capitalize ">{{ themeConfig.app.title }}</span>
          </h4>
        </VCardText>
        <VCardText>
          <VForm
            ref="loginForm"
            @submit.prevent="onSubmit"
          >
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.employee_code"
                  autofocus
                  label="Employee Code"
                  type="text"
                  placeholder="Enter your employee code"
                  :rules="[requiredValidator]"
                  :error-messages="errors.employee_code"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.password"
                  label="Password"
                  placeholder="············"
                  :rules="[requiredValidator]"
                  :error-messages="errors.password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
                <div class="d-flex align-center flex-wrap justify-space-between mt-2 mb-4">
                  <VCheckbox
                    v-model="form.remember"
                    label="Remember me"
                  />
                  <a
                    class="text-primary ms-2 mb-1"
                    href="#"
                  >
                    Forgot Password?
                  </a>
                </div>

                <VBtn
                  type="submit"
                  block
                  :loading="loading"
                >
                  Login
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
        <VCardActions class=" justify-between">
          <span class="text-overline me-3">
            Powered by
          </span>
          <VSpacer />
          <a
            href="https://www.assetsadm.com"
            target="_blank"
            rel="noopener noreferrer"
            class="text-primary ms-1"
          >
            <VNodeRenderer
              :nodes="themeConfig.app.bigLogo"
              class="mb-6"
            />
          </a>
        </VCardActions>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
