<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'
import InvestigationGroupDrawer from '@/views/incident/master/InvestigationGroupDrawer.vue'
import InvestigationGroupService from '@/services/incident/investigationGroup.service'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: 'incident-master-investigation-group',

    // subject: 'master.form_type',
    // action: 'read',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Users',
    key: 'users',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: investigationGroupListing,
  execute: fetchInvestigationGroupListing,
} =  await useApi(createUrl('/masters/investigation-groups', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
  },
}))

const investigationGroups = computed(() => investigationGroupListing.value?.data)
const totalInvestigationGroups = computed(() => investigationGroupListing.value?.meta?.total)

const isDrawerVisible = ref(false)
const isViewOnly = ref(false)
const investigationGroupData = ref()

const showInvestigationGroup = (data = null, viewOnly = false) => {
  investigationGroupData.value = data
  isDrawerVisible.value = true
  isViewOnly.value = viewOnly
}

watch(isDrawerVisible, value => {
  if (value === false) {
    investigationGroupData.value = null
    isViewOnly.value = false
  }
})

const refConfirmDialog = ref()

const showConfirmDialog = data => {
  investigationGroupData.value = data
  isConfirmDialogVisible.value = true
}

const deleteInvestigationGroup = async (confirm, data) => {
  if(data?.id && confirm){
    InvestigationGroupService.destroy(
      data.id, 
      () => {
        refConfirmDialog.value.onSuccess()
        isConfirmDialogVisible.value = false
        fetchInvestigationGroupListing()
      },
      () => {
        refConfirmDialog.value.onError()
      },
    )
  }
}

watch(isConfirmDialogVisible, value => {
  if (value === false) investigationGroupData.value = null
})
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <!-- <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn> -->
       
          <VBtn
            v-if="$can('create', 'master.form_type')"
            prepend-icon="tabler-plus"
            @click="showInvestigationGroup(null)"
          >
            Add Investigation Group
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="investigationGroups"
        :items-length="totalInvestigationGroups"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :formTypeData="item"
            @click="showInvestigationGroup(item, true)"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.users="{ item }">
          <!-- <div class="d-flex gap-2">
            <VChip
              v-for="user in item.users"
              :key="user.id"
            >
              {{ user.name }}
            </VChip>
          </div> -->

          <div class="v-avatar-group">
            <template
              v-for="(user, index) in item.users"
              :key="user"
            >
              <VAvatar
                v-if="item.users.length > 4 && item.users.length !== 4 && index < 3"
                size="36"
                :color="$vuetify.theme.current.dark ? '#4A5072' : '#f6f6f7'"
              >
                <VTooltip
                  activator="parent"
                  location="end"
                >
                  {{ user.name }}
                </VTooltip>
                <VImg 
                  v-if="user.avatar"
                  :src="user.avatar"
                />
                <span>
                  {{ avatarText(user.name) }}
                </span>
              </VAvatar>

              <VAvatar
                v-if="item.users.length < 4"
                size="36"
                :color="$vuetify.theme.current.dark ? '#4A5072' : '#f6f6f7'"
              >
                <VTooltip
                  activator="parent"
                  location="end"
                >
                  {{ user.name }}
                </VTooltip>
                <VImg 
                  v-if="user.avatar"
                  :src="user.avatar"
                />
                <span>
                  {{ avatarText(user.name) }}
                </span>
              </VAvatar>
            </template>
            <VAvatar
              v-if="item.users.length > 4"
              :color="$vuetify.theme.current.dark ? '#4A5072' : '#f6f6f7'"
            >
              <span>
                +{{ item.users.length - 3 }}
              </span>
            </VAvatar>
          </div>
        </template>
    
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn 
            v-if="$can('delete', 'master.form_type')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn 
            v-if="$can('update', 'master.form_type')"
            @click="showInvestigationGroup(item)"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalInvestigationGroups) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalInvestigationGroups / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalInvestigationGroups / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <InvestigationGroupDrawer
      v-model:isDrawerOpen="isDrawerVisible"
      :form-data="investigationGroupData"
      :view-only="isViewOnly"
      @refetch-listing="fetchInvestigationGroupListing"
    />
    <ConfirmDialog
      ref="refConfirmDialog"
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="Investigation Group Deleted."
      confirm-title="Deleted!"
      cancel-title="Error!"
      cancel-msg="Something Went Wrong."
      :is-confirm-visible="false"
      :is-error-visible="false"
      :data="investigationGroupData"
      @confirm="deleteInvestigationGroup"
    />
  </section>
</template>
