import { useEnum } from "../stores/enum"
import { AbortController } from 'ofetch'

// 👉 IsEmpty
export const isEmpty = value => {
  if (value === null || value === undefined || value === '')
    return true
  
  return !!(Array.isArray(value) && value.length === 0)
}

// 👉 IsNullOrUndefined
export const isNullOrUndefined = value => {
  return value === null || value === undefined
}

// 👉 IsEmptyArray
export const isEmptyArray = arr => {
  return Array.isArray(arr) && arr.length === 0
}

// 👉 IsObject
export const isObject = obj => obj !== null && !!obj && typeof obj === 'object' && !Array.isArray(obj)

// 👉 IsToday
export const isToday = date => {
  const today = new Date()
  
  return (date.getDate() === today.getDate()
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear())
}

export const getEnumValue =async (enumName, isPrivate=false, filterKey=null) => {

  const enumData = await useEnum().fetchEnumByName(enumName, isPrivate)

  if (filterKey) {
    // object filter
    return computed(() => {
      return Object.keys(enumData[filterKey]).map(key => ({
        value: key,
        title: enumData[key],
      }))
    })
  }
  
  return computed(() => {
    return  Object.keys(enumData).map(key => ({
      value: key,
      title: enumData[key],
    }))??[]
  })

}

export const useListFetchingService = (url, params, beforeFetch={})=> {
  // Assuming params is a reactive object that can be watched for changes
  const { isFetching, data: dataList } = useApi(createUrl(url, params), {
    ...beforeFetch,
    onSuccess: data => {
      dataList.value = data
    },
  })

  const data = computed(() => {
    return dataList.value ?? []
  })

  // Return the loading state and the data list
  return {
    isFetching,
    data,
  }
}

export const updateObjectAsSelect=items=>{
  return items.map(item => {
    if (item.icon)
      item.icon = { icon: item.icon }
    if(item.color)
      item.color = item.color
    item.value = item.id
    item.title = item.name
    item.label = item.name
    
    return item
  })
}


export const resolveTicketStatusColor=status=>{
  switch (status) {
  case "Pending":
    return "warning"
  case "In Progress":
    return "warning"
  case "Snooze":
    return "info"
  case "Re-Assign":
    return "info"
  case "Re-Open":
    return "info"
  case "Resolved":
    return "success"
  case "Disposed":
    return "error"
  case "Unattended":
    return "error"
  case "Noted Suggestion":
    return "error"
  case "Update":
    return "error"
  }
}


export const resolveTicketTypeColor = type => {
  const typeConfigs = {
    Complaint: {
      color: 'error',
      icon: 'tabler-alert-triangle',
    },
    Suggestion: {
      color: 'warning',
      icon: 'tabler-info-circle',
    },
    'Special Request': {
      color: 'info',
      icon: 'tabler-military-rank',
    },
    Compliment: {
      color: 'success',
      icon: 'tabler-mood-smile',
    },
    Query: {
      color: 'info',
      icon: 'tabler-message-question',
    },
    Feedback: {
      color: 'success',
      icon: 'tabler-clipboard-text',
    },
  }

  // Return the config for the type or the default config
  return typeConfigs[type] || {
    color: 'primary', // Changed from 'success' to 'primary' to avoid duplication
    icon: 'tabler-ticket',  // Changed to a different icon to avoid duplication
  }
}

export const resolveCapaStatusColor=status=>{
  switch (status) {
  case "Pending":
    return "warning"
  case "Re-Open":
    return "error"
  case "Done":
    return "success"
  default:
    return "info"
  }
}

export const resolveEscalationLevelBlock=level=>{
  switch (level) {
  case 1:
    return h("div", { class: "badge bg-success" }, "Level 1")
  case 2:
    return h("div", { class: "badge bg-warning" }, "Level 2")
  case 3:
    return h("div", { class: "badge bg-danger" }, "Level 3")
  default:
    return h("div", { class: "badge bg-info" }, "Level 0")
  }
}

export const resolveSmiley = value => {
  if (value >= 9) {
    return {
      icon: 'tabler-mood-happy-filled',
      color: 'success',
    }
  } else if (value >= 6) {
    return {
      icon: 'tabler-mood-neutral-filled',
      color: 'warning',
    }
  } else {
    return {
      icon: 'tabler-mood-sad-filled',
      color: 'error',
    }
  }
}



export const resolveFeedbackAvatar = status => {
  // 'Action Taken', 'Auto NFA', 'NFA', 'Pending''
  switch (status) {
  case 'Action Taken':
    return {
      color: 'success',
      icon: 'tabler-checks',
    }
  case 'Auto NFA':
    return {
      color: 'info',
      icon: 'tabler-check',
    }
  case 'NFA':
    return {
      color: 'error',
      icon: 'tabler-alert-circle',
    }
  case 'Pending':
    return {
      color: 'warning',
      icon: 'tabler-circle-x',
    }
  default:
    return {
      color: 'primary',
      icon: 'tabler-circle-x',
    }
  }
}

export const resolveCustomerCommunicationAvatar = status => {
  switch (status) {
  case 'Connected':
    return {
      color: 'success',
      icon: 'tabler-checks',
    }
  case 'Not Reachable':
    return {
      color: 'info',
      icon: 'tabler-phone-off',
    }
  case 'Not Connected':
    return {
      color: 'error',
      icon: 'tabler-circle-x',
    }
  case 'Pending':
    return {
      color: 'warning',
      icon: 'tabler-rotate-clockwise-2',
    }
  default:
    return {
      color: 'primary',
      icon: 'tabler-circle-x',
    }
  }
}

export const chartHoverCursorPointer = event => {
  event.target.style.cursor = "pointer";
}

export const downloadFile = (url, fileName, onSuccess, onError) => {
  let abortController = new AbortController()
  const { signal } = abortController
  let progress = 0
  const requestUrl = createUrl(url)
  
  $api(requestUrl.value, { signal }, (loaded, total) => {
    progress = total ? Math.floor((loaded / total) * 100) : 0
  }).then(data => {
    const downloadUrl = window.URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
    window.URL.revokeObjectURL(downloadUrl)
    onSuccess()
  }).catch(error => {
    if (error.name !== 'AbortError') {
      console.error('Download error:', error)
    }
    onError(error)
  })
}

// resolve incident status color
// DRAFT: 'DRAFT',
//   REQUEST_SUBMITTED: 'REQUEST_SUBMITTED',
//   DELEGATION_PENDING: 'DELEGATION_PENDING',
//   RCA_PENDING: 'RCA_PENDING',
//   REJECTED: 'REJECTED',
//   VERIFICATION_PENDING: 'VERIFICATION_PENDING',
//   CLOSURE_PENDING: 'CLOSURE_PENDING',
//   CLOSED: 'CLOSED',
export const resolveIncidentStatusColor = status => {
  switch (status) {
  case 'DRAFT':
    return 'secondary'
  case 'REQUEST_SUBMITTED':
    return 'primary'
  case 'DELEGATION_PENDING':
    return 'info'
  case 'RCA_PENDING':
    return 'warning'
  case 'REJECTED':
    return 'error'
  case 'VERIFICATION_PENDING':
    return 'warning'
  case 'CLOSURE_PENDING':
    return 'warning'
  case 'CLOSED':
    return 'success'
  default:
    return 'info'
  }
}

export const resolveIncidentTypeIconColor = type => {
  switch (type) {
  case 'Clinical':
    return {
      icon: 'tabler-heart',
      color: 'error',
    }
  case 'Non-Clinical':
    return {
      icon: 'tabler-heart-off',
      color: 'primary',
    }
  default:
    return 'warning'
  }
}

export const checkImageUrl = url => {
  return new Promise(resolve => {
    const img = new Image()

    img.src = url
    img.onload = () => resolve(true)
    img.onerror = () => resolve(false)
  })
}

export const currentModule = () => {
  return window.location.pathname.split("/")[2]
}

export const MODULES = [
  {
    name: "Feedback",
    desc: "Manage Feedbacks",
    icon: "tabler-clipboard-text",
    to: '/app/feedback/dashboard',
  },
  {
    name: "Incident",
    desc: "Manage Incidents",
    icon: "tabler-traffic-cone",
    to: '/app/incident/dashboard',
  },
]

export const convertToFormData = data => {
  const formData = new FormData()

  // Helper function to append nested objects
  const appendFormData = (formData, key, value) => {
    if (value instanceof File) {
      // Directly append File objects
      formData.append(key, value)
    } else if (Array.isArray(value)) {
      // Handle arrays
      value.forEach((item, index) => {
        appendFormData(formData, `${key}[${index}]`, item)
      })
    } else if (typeof value === 'object' && value !== null) {
      // Handle objects recursively
      Object.keys(value).forEach(subKey => {
        appendFormData(formData, `${key}[${subKey}]`, value[subKey])
      })
    } else if (value === null) {
      // Skip the value
    } else {
      // Append primitive values
      formData.append(key, value)
    }
  }

  // Iterate over the main object
  Object.keys(data).forEach(key => {
    appendFormData(formData, key, data[key])
  })

  return formData
}

export const matchObject = (obj1, obj2) => {
  // If both are strictly the same (including values)
  if (obj1 === obj2) return true

  // If either is not an object (or is null), return false
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false
  }

  // Handle arrays explicitly
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false
    for (let i = 0; i < obj1.length; i++) {
      if (!matchObject(obj1[i], obj2[i])) return false // Recursively compare each element
    }
    
    return true
  }

  // Get all keys from both objects
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // If objects don't have the same number of keys, return false
  if (keys1.length !== keys2.length) return false

  // Check if both objects have the same keys and values
  for (let key of keys1) {
    // Ignore the 'created_at' and 'updated_at' fields
    // if (key === 'created_at' || key === 'updated_at') continue;

    // Check recursively for nested objects or arrays
    if (!matchObject(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 3) + '...'
  }
  
  return text
}

export const executeWithLoader = async (loader, callback) => {
  loader.value = true
  try {
    const result = await callback()
    
    loader.value = false

    return result
  } catch(error) {
    console.error(error)
    loader.value = false
  }
}

export const formatRating = input => {
  const number = parseFloat(input)
  if (isNaN(number)) return

  return number.toFixed(1)
}

export const randomColor = () => {
  const colors = ['#7367f0', '#29c76f', '#01bad1']
  
  return colors[Math.floor(Math.random() * colors.length)]
}

export const iconPicker = () => {
  return [
    'tabler-building-hospital',
    'tabler-clipboard-text',
    'tabler-traffic-cone',
    'tabler-nurse',
    'tabler-first-aid-kit',
    'tabler-first-aid-kit-off',
  ]
}

export const uniqueArray = (array, key = null) => {
  if (key) {
    return array.filter((v, i, arr) => arr.findIndex(obj => obj[key] === v[key]) === i)
  } else {
    return array.filter((v, i, arr) => arr.findIndex(obj => JSON.stringify(obj) === JSON.stringify(v)) === i)
  }
}

export const checkMediaDeletePermission = (media, loggedInUserId) => {
  return !media.uuid || media?.custom_properties?.user_id == loggedInUserId
}
