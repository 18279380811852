<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'
import CategoryDrawer from '@/views/incident/master/CategoryDrawer.vue'
import CategoryService from '@/services/incident/category.service'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: 'incident-master-category',

    // subject: 'master.form_type',
    // action: 'read',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Code',
    key: 'code',
  },
  // {
  //   title: 'Department',
  //   key: 'department_id',
  // },
  {
    title: 'Parent Category',
    key: 'parent_category',
  },
  // {
  //   title: 'Status',
  //   key: 'status',
  // },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: categoryListing,
  execute: fetchCategoryListing,
} =  await useApi(createUrl('/masters/incident-categories', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
  },
}))

const categories = computed(() => categoryListing.value?.data)
const totalCategories = computed(() => categoryListing.value?.meta?.total)

const isDrawerVisible = ref(false)
const isViewOnly = ref(false)
const investigationGroupData = ref()

const showCategory = (data = null, viewOnly = false) => {
  investigationGroupData.value = data
  isDrawerVisible.value = true
  isViewOnly.value = viewOnly
}

watch(isDrawerVisible, value => {
  if (value === false) {
    investigationGroupData.value = null
    isViewOnly.value = false
  }
})

const refConfirmDialog = ref()

const showConfirmDialog = data => {
  investigationGroupData.value = data
  isConfirmDialogVisible.value = true
}

const deleteCategory = async (confirm, data) => {
  if(data?.id && confirm){
    await CategoryService.destroy(
      data.id, 
      () => {
        refConfirmDialog.value.onSuccess()
        isConfirmDialogVisible.value = false
        fetchCategoryListing()
      },
      () => {
        refConfirmDialog.value.onError()
      },
    )
  }
}

watch(isConfirmDialogVisible, value => {
  if (value === false) investigationGroupData.value = null
})
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <!-- <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn> -->
       
          <VBtn
            v-if="$can('create', 'master.form_type')"
            prepend-icon="tabler-plus"
            @click="showCategory(null)"
          >
            Add Category
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="categories"
        :items-length="totalCategories"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :formTypeData="item"
            @click="showCategory(item, true)"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.users="{ item }">
          <div class="d-flex gap-2">
            <VChip
              v-for="user in item.users"
              :key="user.id"
            >
              {{ user.name }}
            </VChip>
          </div>
        </template>
        <template #item.code="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.code }}</span>
        </template>
        <template #item.parent_category="{ item }">
          {{ item.parent?.name ?? 'N/A' }}
        </template>
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn 
            v-if="$can('delete', 'master.form_type')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn 
            v-if="$can('update', 'master.form_type')"
            @click="showCategory(item)"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalCategories) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalCategories / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalCategories / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <CategoryDrawer
      v-model:isDrawerOpen="isDrawerVisible"
      :form-data="investigationGroupData"
      :view-only="isViewOnly"
      @refetch-listing="fetchCategoryListing"
    />
    <ConfirmDialog
      ref="refConfirmDialog"
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="Category Deleted."
      confirm-title="Deleted!"
      cancel-title="Error!"
      cancel-msg="Something Went Wrong."
      :is-confirm-visible="false"
      :is-error-visible="false"
      :data="investigationGroupData"
      @confirm="deleteCategory"
    />
  </section>
</template>
