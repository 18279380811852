<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import teamService from "@/services/team.service"
import TeamDrawer from '@/views/feedback/master/TeamDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: 'master-team',
    subject: 'master.team',
    action: 'read',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const errors = ref({
  name: [],
  code: [],
  status: [],
})

const onError = error => {
  if (error.data){
    
    errors.value = error.data.errors
  }
}

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order.toUpperCase()
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },
 
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: teamsData,
  execute: fetchTeams,
} =  await useApi(createUrl('/masters/teams', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
  },
}))

const teams = computed(() => teamsData.value?.data)
const totalTeams = computed(() => teamsData.value?.meta?.total)

const updateTeam = data => {
  if (data.id){
    teamService.updateTeam(data.id, data, ()=>
    {
      fetchTeams()
      isTeamDrawerVisible.value = false
      resetErrors()
    }, onError)
   
  }
}

const saveTeam = data => {
  teamService.addTeam(data, ()=>
  {
    fetchTeams()
    isTeamDrawerVisible.value = false
    resetErrors()

  }, onError)
}

const resetErrors = () => {
  errors.value = {
    name: [],
    code: [],
    status: [],
  }
}

const isTeamDrawerVisible = ref(false)
const teamDrawerData = ref()
const teamDeleteData = ref()
const teamDrawerType = ref('Add')

const showTeam = (teamData, type) => {
  teamDrawerData.value = teamData
  teamDrawerType.value = type  
  isTeamDrawerVisible.value = true
}

const deleteTeam = async (confirm, data) => {
  if(data?.id && confirm ){
    await teamService.deleteTeam(data.id, () => {
      fetchTeams()
      isConfirmDialogVisible.value = false
    }, onError)
  }
}

const showConfirmDialog = teamData => {
  teamDeleteData.value = teamData
  isConfirmDialogVisible.value = true
}

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value){
    teamDeleteData.value = {}
  }
})
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

       
          <VBtn
            v-if="$can('create', 'master.team')"
            prepend-icon="tabler-plus"
            @click="showTeam({},'Add')"
          >
            Add New Team
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="teams"
        :items-length="totalTeams"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :teamData="item"
            @click="showTeam(item,'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.code="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.code }}</span>
        </template>
        <template #item.status="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.status?'Active':"Inactive" }}</span>
        </template>
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn 
            v-if="$can('delete', 'master.team')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn
            v-if="$can('update', 'master.team')"
            @click="showTeam(item,'Edit')"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalTeams) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalTeams / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalTeams / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <TeamDrawer
      v-model:isDrawerOpen="isTeamDrawerVisible"
      :team-data="teamDrawerData"
      :type="teamDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="teamDrawerType = $event"
      @update:team-data="updateTeam"
      @save-team="saveTeam"
    />
    <ConfirmDialog
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="Team Deleted."
      confirm-title="Deleted!"
      :data="teamDeleteData"
      @confirm="deleteTeam"
    />
  </section>
</template>
