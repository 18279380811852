import { userAuthState } from "@/@core/auth"



export const redirects = [
  // ℹ️ We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/app/',
    name: 'app',
    redirect(to) {
      const authState = userAuthState()
      const isLoggedIn = authState.isLoggedIn

      if (!isLoggedIn) return { name: 'app-login', query: to.query }
      
      return { name: 'app-panel' }
    },
  },
  {
    path: '/',
    name: 'index',
    redirect(to) {
      const authState = userAuthState()
      const isLoggedIn = authState.isLoggedIn

      if (!isLoggedIn) return { name: 'app-login', query: to.query }
      
      return { name: 'app-panel' }
    },
  },
]

export const routes = [
  // Global Settings Routes
  {
    path: '/app/global/users/:id/:tab',
    name: 'app-global-user-tab',
    component: () => import('@/pages/app/global/users/user.vue'),
    meta: {
      name: 'user-update',
      navActiveLink: 'users',
      subject: 'master.user',
      action: 'update',
    },
  },
  {
    path: '/app/global/users/add',
    name: 'app-user-add',
    component: () => import('@/pages/app/global/users/user.vue'),
    meta: {
      name: 'user-create',
      navActiveLink: 'users',
      subject: 'master.user',
      action: 'create',
    },
  },
  {
    path: '/app/global/escalation/:departmentId/:categoryId?',
    name: 'app-global-escalation-create-update',
    component: () => import('@/pages/app/global/escalation/create-update.vue'),
    meta: {
      navActiveLink: 'users',
    },
    props: true,
  },

  // Feedback Routes
  {
    path: '/app/feedback/questionnaire/add',
    name: 'app-feedback-question-add',
    component: () => import('@/pages/app/feedback/questionnaire/question.vue'),
    meta: {
      subject: 'master.question',
      action: 'create',
      navActiveLink: 'questionnaire',
    },
  },
  {
    path: '/app/feedback/questionnaire/:id',
    name: 'app-feedback-question-tab',
    component: () => import('@/pages/app/feedback/questionnaire/question.vue'),
    meta: {
      subject: 'master.question',
      action: 'update',
      navActiveLink: 'questionnaire',
    },
  },
  {
    path: '/app/feedback/entry/:type/collect',
    name: 'app-feedback-collection',
    component: () => import('@/pages/app/feedback/entry/collection/index.vue'),
    meta: {
      subject: 'feedback.feedback',
      action: 'create',
      navActiveLink: route => route.params.type === 'assorted' 
        ? 'app-feedback-entry-assorted' 
        : 'app-feedback-entry',
    },
  },

  // Incident Routes
  {
    path: '/app/incident/entry/:id?',
    name: 'app-incident-entry',
    component: () => import('@/pages/app/incident/incident/entry/index.vue'),
    meta: {
      subject: 'incident.incident',
      action: 'create',
      navActiveLink: 'app-incident-incident',
    },
    props: true,
  },
  {
    path: '/app/incident/view/:id',
    name: 'app-incident-view',
    component: () => import('@/pages/app/incident/incident/entry/view.vue'),
    meta: {
      subject: 'incident.incident',
      action: 'read',
      navActiveLink: 'app-incident-incident',
    },
    props: true,
  },

  // {
  //   path: '/app/incident/rca/investigation/:id',
  //   name: 'app-incident-rca-investigation',
  //   component: () => import('@/pages/app/incident/rca/investigation/index.vue'),
  //   meta: {
  //     navActiveLink: 'app-incident-incident',
  //   },
  //   props: true,
  // },

  {
    path: '/app/incident/rca/view/:id',
    name: 'app-incident-rca-view',
    component: () => import('@/pages/app/incident/rca/view.vue'),
    meta: {
      subject: 'incident.incident_rca',
      action: 'read',
      navActiveLink: 'app-incident-rca',
    },
    props: true,
  },

  {
    path: '/app/incident/master/question/entry/:id?',
    name: 'app-incident-master-question-entry',
    component: () => import('@/pages/app/incident/master/question/entry.vue'),
    meta: {
      navActiveLink: 'app-incident-master-question',
    },
    props: true,
  },

  // System Routes
  {
    path: '/invalid-url/:code?/:title?/:description?',
    name: 'invalid-url',
    component: () => import('@/views/misc/invalid-url.vue'),
    meta: {
      layout: 'client',
      public: true,
    },
    props: true,
  },
]

