<script setup>
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { integerValidator, requiredValidator } from '@/@core/utils/validators'
import { cookieRef } from '@/@layouts/stores/config'
import { VDataTable } from 'vuetify/lib/labs/VDataTable/index.mjs'
import AppCombobox from '@/@core/components/app-form-elements/AppCombobox.vue'
import AppSelect from '@/@core/components/app-form-elements/AppSelect.vue'
import AppTextField from '@/@core/components/app-form-elements/AppTextField.vue'
import QuestionService from '@/services/incident/question.service'
import QuestionOptionService from '@/services/incident/questionOption.service'

definePage({
  meta: {
    name: "app-incident-master-question-entry",
  },
})

const route = useRoute('users')
const router = useRouter()
const questionId = computed(() => route.params?.id)
const unitId = computed(() => cookieRef('selectedUnit')?.value)

const questionData = ref()

await useApi(createUrl(`/masters/incident-questions/${ questionId.value }`), {
  beforeFetch: ({ cancel }) => {
    if (!questionId.value) cancel()
  },
  afterFetch: ({ data }) => {
    questionData.value = data
  },
})

const form = ref({})

const questionTypeEnum = await getEnumValue('QuestionTypes', true)
const formSections = await getEnumValue('FormSections', true)
const questionTypes = questionTypeEnum.value.find(item => item.value === 'INCIDENT_QUESTION_TYPES')?.title
const questionTypeOptionNotRequired = questionTypeEnum.value.find(item => item.value === 'INCIDENT_OPTION_NOT_REQUIRED')?.title
// const { isFetching: isFetchingUnit, data: units } = useListFetchingService(createUrl('/masters/units'))

const { 
  isFetching: isFetchingCategory,
  data: categories,
} = await useListFetchingService(createUrl('/masters/incident-categories', {
  query: {
    search: {
      parent_id: 'null',
    },
  },
}))

const category = computed(() => form.value?.category_id)

const { 
  isFetching: isFetchingChildCategories,
  data: childCategories,
} = await useListFetchingService(createUrl('/masters/incident-categories', {
  query: {
    search: {
      parent_id: category,
    },
  },
}), {
  beforeFetch: ({ cancel }) => {
    if (!category.value) cancel()
  },
})

const refForm = ref()
const loadingForm = ref(false)

const isEditable = computed({
  get: () => form.value?.is_active === 1,
  set: value => {
    form.value.is_active = value? 1 : 0
  },
})

const cannotHaveOptions = computed(()=> {
  // if (!questionData.value?.id) {
  //   return true
  // }

  if (form.value?.question_type === undefined || form.value?.question_type === null) {
    return true
  }

  return questionTypeOptionNotRequired.includes(form.value?.question_type)
})

const submitForm = async () => {
  loadingForm.value = true
  await refForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid) {

      // Formate Data
      const {
        question_options,
        ...formatData
      } = form.value

      formatData.unit_id = unitId.value
      
      // formatData.options = question_options

      if (questionData.value?.id) {
        QuestionService.update(
          questionData.value.id,
          formatData,
          data => {
            questionData.value = data
          }, // Success
          () => {}, // Error
        )
      } else {
        QuestionService.create(
          formatData,
          data => {
            questionData.value = data
            router.push({ name: 'app-incident-master-question-entry', params: { id: data.id } })
          }, // Success
          () => {}, // Error
        )
      }

    }
  })
  loadingForm.value = false
}

const questionOptions = computed(() => form.value?.question_options)

const optionTableHeaders = [
  { title: 'Label', key: 'label' },
  { title: 'Value', key: 'value' },
  { title: 'Status', key: 'status' },
  { title: 'Actions', key: 'actions' },
]

const isOptionFormDialogVisible = ref(false)

const refOptionForm = ref()
const loadingOptionForm = ref(false)

const optionForm = ref({
  label: '',
  value: '',
  status: true,
})

const submitOptionForm = async () => {
  loadingOptionForm.value = true
  await refOptionForm.value?.validate().then(({ valid: isValid }) => {
    if (isValid) {

      if (questionData.value?.id && optionForm.value.id) {
        QuestionOptionService.update(
          optionForm.value.id,
          optionForm.value,
          data => {
            // Update listing
            const index = form.value.question_options.findIndex(item => item.id === data.id)

            form.value.question_options[index] = data
            isOptionFormDialogVisible.value = false
          },
          () => {},
        )
      } else {
        QuestionOptionService.create(
          questionData.value.id,
          optionForm.value,
          data => {
            // Update listing
            form.value.question_options.push(data)

            // resetOptionForm()
            isOptionFormDialogVisible.value = false
          },
          () => {},
        )
      }

    }
  })
  loadingOptionForm.value = false
}

const editOption = option => {
  optionForm.value = toRaw(option)
  isOptionFormDialogVisible.value = true
}

const isOptionDeleteDialogVisible = ref(false)

const refOptionDeleteConfirmDialog = ref()

const showOptionDeleteDialog = data => {
  optionForm.value = toRaw(data)
  isOptionDeleteDialogVisible.value = true
}

const deleteOption = async (confirm, data) => {
  console.log(data)
  if (data?.id && confirm) {
    await QuestionOptionService.destroy(data.id,
      () => {
        refOptionDeleteConfirmDialog.value.onSuccess()
        form.value.question_options = form.value.question_options.filter(item => item.id !== data.id)
      },
      () => {
        refOptionDeleteConfirmDialog.value.onError()
      },
    )
  }
}

watch(() => isOptionDeleteDialogVisible.value, value => {
  if (value === false) {
    optionForm.value = {
      label: '',
      value: '',
      status: true,
    }
  }
})

watch(() => isOptionFormDialogVisible.value, value => {
  if (value === false) {
    optionForm.value = {
      label: '',
      value: '',
      status: true,
    }
  }
}, { deep: true })

watch(() => questionData.value, () => {
  form.value = {
    label: questionData.value?.label ?? null,
    question_type: questionData.value?.question_type ?? null,
    category_id: questionData.value?.category_id ?? null,
    child_category_id: questionData.value?.child_category_id ?? null,
    order: questionData.value?.order ?? null,
    is_required: questionData.value?.is_required ?? false,
    status: questionData.value?.status ?? true,
    form_section: questionData.value?.form_section ?? null,
    question_options: questionData.value?.incident_question_options ?? [],
  }
}, { immediate: true })

watch(() => form.value.category_id, () => {
  form.value.child_category_id = null
})
</script>

<template>
  <VRow>
    <VCol cols="12">
      <VCard title="Question Details">
        <VCardText class="pt-2">
          <!-- Question Form -->
          <VForm
            ref="refForm"
            @submit.prevent="submitForm"
          >
            <VRow>
              <VCol
                cols="12"
                md="6"
                lg="6"
              >
                <AppSelect
                  v-model="form.form_section"
                  :items="formSections"
                  :item-title="item => item.title"
                  :item-value="item => item.value"
                  label="Form Sections"
                  placeholder="Select Form Section"
                  :return-object="false"
                  :clearable="true"
                  :rules="[requiredValidator]"
                  :required="true"
                />
              </VCol>

              <VCol
                cols="12"
                md="6"
                lg="6"
              >
                <AppSelect
                  v-model="form.question_type"
                  label="Question Type"
                  :items="questionTypes"
                  :item-title="item =>  snakeTitleCase(item)"
                  :item-value="item => item"
                  clearable
                  placeholder="Select Question Type"
                  :rules="[requiredValidator]"
                  :required="true"
                />
              </VCol>

              <VCol
                cols="12"
                md="6"
                lg="6"
              >
                <AppCombobox
                  v-model="form.category_id"
                  :items="categories"
                  :item-title="item => item.name"
                  :item-value="item => item.id"
                  label="Category"
                  placeholder="Select Category"
                  :return-object="false"
                  :clearable="true"
                  :rules="[requiredValidator]"
                  :required="true"
                />
              </VCol>

              <VCol
                cols="12"
                md="6"
                lg="6"
              >
                <AppCombobox
                  v-model="form.child_category_id"
                  :loading="isFetchingChildCategories"
                  :items="childCategories ?? []"
                  :item-title="item => item.name"
                  :item-value="item => item.id"
                  label="Subcategory"
                  placeholder="Select Subcategory"
                  :return-object="false"
                  :clearable="true"
                  :rules="[requiredValidator]"
                  :required="true"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="form.label"
                  placeholder="Enter Question Label"
                  label="Question Label"
                  :rules="[requiredValidator]"
                  :required="true"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="form.order"
                  placeholder="Enter Order"
                  label="Order"
                  :rules="[integerValidator]"
                  :required="true"
                />
              </VCol>

              <VCol>
                <div class="d-flex gap-10">
                  <div>
                    <VLabel text="Is this question required?" />
                    <VSwitch
                      v-model="form.is_required"
                      class="mt-1"
                      :label="form.is_required ? 'Mandatory' : 'Optional'"
                    />
                  </div>
                  
                  <div>
                    <VLabel text="Is this question visible?" />
                    <VSwitch
                      v-model="form.status"
                      class="mt-1"
                      :label="form.status ? 'Active' : 'Inactive'"
                    />
                  </div>
                </div>
              </VCol>
            </VRow>
            <VRow>
              <!-- 👉 Form Actions -->
              <VCol
                cols="12"
                class="d-flex flex-row-reverse gap-4"
              >
                <VBtn
                  :loading="loadingForm"
                  color="primary"
                  type="submit"
                >
                  {{ questionData ? 'Update' : 'Create' }} Question 
                </VBtn>

                <!-- <VBtn
                  color="secondary"
                  variant="tonal"
                  type="reset"
                  @click.prevent="resetForm"
                >
                  Reset
                </VBtn> -->
              </VCol>
            </VRow>
          </VForm>
          <!-- End Form -->
        </VCardText>
      </VCard>
    </VCol>

    <!-- Question Option Card -->
    <VCol 
      v-if="!cannotHaveOptions"
      cols="12"
    >
      <VCard>
        <VCardText v-if="!questionData?.id">
          <VAlert icon="tabler-info-circle">
            Please submit the question form to configure question options.
          </VAlert>
        </VCardText>
        <VCardText title="Question Options">
          <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
            <div class="text-h5">
              Configure Question Option
            </div>
            <VSpacer />
            <VBtn
              prepend-icon="tabler-plus"
              :disabled="!questionData?.id"
              @click="isOptionFormDialogVisible = true"
            >
              Add Options
            </VBtn>
          </div>
        </VCardText>
        <VCardText>
          <VDataTable
            :items="questionOptions"
            :headers="optionTableHeaders"
            hide-default-footer
            class="text-no-wrap"
          >
            <template #item.status="{ item }">
              {{ item?.status ? 'Active' : 'Inactive' }}
            </template>
            <!-- actions -->
            <template #item.actions="{ item }">
              <IconBtn
                icon="tabler-edit"
                @click="editOption(item)"
              />
              <IconBtn
                icon="tabler-trash"
                @click="showOptionDeleteDialog(item)"
              />
            </template>
            <template #bottom />
          </VDataTable>
        </VCardText>
      </VCard>
    </VCol>
    <!-- End Question Option Card -->

    <!-- Question Option Dialog Form -->
    <VDialog
      v-model="isOptionFormDialogVisible"
      max-width="500"
    >
      <DialogCloseBtn @click="isOptionFormDialogVisible = !isOptionFormDialogVisible" />

      <VCard title="Question Option">
        <VCardText>
          <VForm
            ref="refOptionForm"
            :disabled="isEditable"
            @submit.prevent="submitOptionForm"
          >
            <VRow>
              <VCol cols="12">
                <AppTextField
                  v-model="optionForm.label"
                  placeholder="Enter Label"
                  label="Label"
                  :rules="[requiredValidator]"
                  :required="true"
                />
              </VCol>
              <VCol cols="12">
                <AppTextField
                  v-model="optionForm.value"
                  placeholder="Enter Value"
                  label="Value"
                  :rules="[requiredValidator]"
                  :required="true"
                />
              </VCol>
              <VCol cols="12">
                <VLabel text="Is this option visible?" />
                <VSwitch
                  v-model="optionForm.status"
                  :default="true"
                  class="mt-1"
                  :label="optionForm.status ? 'Active' : 'Inactive'"
                />
              </VCol>
            </VRow>
            <VRow>
              <!--  show error -->
              <VCol
                cols="12"
                class="d-flex flex-row-reverse gap-4"
              >
                <VBtn
                  :loading="loadingOptionForm"
                  color="primary"
                  type="submit"
                >
                  {{ optionForm.id ? 'Update' : 'Create' }} Question Option
                </VBtn>
                <VBtn
                  color="secondary"
                  variant="tonal"
                  @click="isOptionFormDialogVisible = !isOptionFormDialogVisible"
                >
                  Close
                </VBtn>
                <!-- <VBtn
                  color="secondary"
                  variant="tonal"
                  type="reset"
                  @click.prevent="() => {}"
                >
                  Reset
                </VBtn> -->
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VDialog>
    <!-- End Question Option Dialog Form -->

    <!-- Question Option Delete Confirmation Dialog -->
    <ConfirmDialog
      ref="refOptionDeleteConfirmDialog"
      v-model:isDialogVisible="isOptionDeleteDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="Question Option Deleted."
      confirm-title="Deleted!"
      cancel-title="Error!"
      cancel-msg="Something Went Wrong."
      :is-confirm-visible="false"
      :is-error-visible="false"
      :data="optionForm"
      @confirm="deleteOption"
    />
    <!-- End Question Option Delete Confirmation Dialog -->
  </VRow>
</template>
