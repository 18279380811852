import { userAuthState } from '@/@core/auth'
import { router } from '@/plugins/2.router'
import { createFetch } from '@vueuse/core'
import { destr } from 'destr'
import useToast from './useToast'


const handleUnauthorizedResponse = () => {
  userAuthState().setToken(null)
  userAuthState().setUser(null)
  localStorage.removeItem('accessToken')

  // Avoid adding a redirect parameter if we're already heading to /login
  if (router.currentRoute.value.name !== 'login') {

    const redirectPath = router.currentRoute.value.fullPath

    router.push({ name: 'app-login', query: { to: redirectPath === '/app/login' ? undefined : redirectPath } })
  }
}


export const useApi = createFetch({
  baseUrl: import.meta.env.VITE_API_BASE_URL || '/api',
  fetchOptions: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
  options: {
    refetch: true,
    async beforeFetch({ options }) {
      const accessToken =localStorage.getItem('accessToken')
      if (accessToken) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${accessToken}`,
        }
      }
      
      return { options }
    },
    afterFetch(ctx) {
      const { data, response } = ctx

      // Parse data if it's JSON
      let parsedData = null
      try {
        parsedData = destr(data)
      }
      catch (error) {
        console.error('Error parsing JSON data:', error)

        console.error(error)
      }

      if(parsedData?.meta) {
        return {
          data: parsedData,
          response,
        }
      }
      if(parsedData?.data) {
        return { data: parsedData?.data, response }
      }
      
      return { data: parsedData, response }
    },

    // on fetch error
    onError(ctx) {
      // if error code is 401, redirect to login page
      console.error('API error:', ctx.error)
      useToast().error('API Error '+ctx.error, 'error')
    },
    onFetchError(ctx) {
      const { response, data } = ctx

      if (response?.status === 401) {
        handleUnauthorizedResponse()
      }
      let parsedData = null
      try {
        parsedData = destr(data)
      }
      catch (error) {
        console.error('Error parsing JSON data:', error)

        console.error(error)
      }
      if (parsedData !== null)
      {
        ctx.error = parsedData
       
      }
      
      return ctx
    },
  },
  onRequest({ error }) {
    console.error('API error:', error)
    useToast().error('API Error '+error, 'error')
  },
})
