import { userAuthState } from '@core/auth'
import { canNavigate } from '@layouts/plugins/casl'
import { isPwaEnabled } from './public-routes'
import { MODULES } from '@/@core/utils/helpers'

export const setupGuards =  router => {
  // 👉 router.beforeEach
  // Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
  router.beforeEach(to => {
    /*
         * If it's a public route, continue navigation. This kind of pages are allowed to visited by login & non-login users. Basically, without any restrictions.
         * Examples of public routes are, 404, under maintenance, etc.
         */

    // default title
    document.title = import.meta.env.VITE_APP_NAME
    if (to.meta.title)
      document.title = `${to.meta.title} - ${import.meta.env.VITE_APP_NAME}`
        
    // Optionally unregister service worker for public routes
    if (!isPwaEnabled(to.path) && 'serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(registration => {
          registration.unregister()
        })
      })
    }
    
    if (to.meta.public)
      return

    /**
         * Check if user is logged in by checking if token & user data exists in local storage
         * Feel free to update this logic to suit your needs
         */

    const auth = userAuthState()
    const isLoggedIn =  auth.isLoggedIn

    /*
          If user is logged in and is trying to access login like page, redirect to home
          else allow visiting the page
          (WARN: Don't allow executing further by return statement because next code will check for permissions)
         */

    if (to.meta.unauthenticatedOnly) return undefined

    if (to.name == 'app-panel' && auth?.user?.modules) {
      const modules = auth.user.modules
      if (modules.length === 1) {
        const module = MODULES.find(x => x.name === modules[0].name)
        
        if (module) return module.to
      } else if (modules.length > 1) {
        return // Go To Panel Page
      }
    }

    if (!canNavigate(to)) {
      return isLoggedIn
        ? { name: 'app-not-authorized' }
        : {
          name: 'app-login',
          query: {
            ...to.query,
            to: to.fullPath !== '/app/' ? to.path : undefined,
          },
        }
    }
    
  })
}
