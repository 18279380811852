import { cookieRef } from '@/@layouts/stores/config'
import { urlBase64ToUint8Array } from '@/@layouts/utils'
import { router } from '@/plugins/2.router'
import { ability } from '@/plugins/casl/ability'
import { $api } from '@/utils/api'
import { defineStore } from 'pinia'


export const userAuthState = defineStore({
  id: 'auth',
  state: () => {
    return {
      user: JSON.parse(localStorage.getItem('user')) ?? null,
      token: localStorage.getItem('accessToken') ?? null,
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'authStorage',
        storage: sessionStorage, // You can also use sessionStorage or custom storage
      },
    ],
  },
  getters: {
    isLoggedIn: state => !!state.token,
    isLoggedOut: state => !state.user,
    isAdmin: state => state.user?.role === 'admin' || state.user?.is_super_admin,
    isAnalyst: state => state.user?.role === 'client',
    isUser: state => state.user?.role === 'guest',
    userAbilities: state => state.user?.abilities ?? [],
    userRole: state => state.user?.role ?? null,
    getUserData: state => {
      if (!state.user && state.token) {
        // call action
        return this.fetchUser()
      }
      
      return state.user
    },
  },
  actions: {
    setUser(userData) {
      // check if abilities changed
      this.user = userData
      if (this.user) {
        if(this.user?.is_super_admin){
          this.user.abilities = [...this.user.abilities, {
            subject: "all",
            action: "manage",
          }]
        }
        const selectedUnit = cookieRef('selectedUnit')

        // validate selected unit exists in user units
        if(selectedUnit.value && !this.user.units.find(unit => unit.id === selectedUnit.value)){
          selectedUnit.value = null
        }
        if(!selectedUnit.value && this.user?.units?.length>0){
          cookieRef('selectedUnit').value = this.user.units[0]?.id
        }

        // Old Logic
        // this.user.abilities = this.user.abilities ?? []
        // ability.update(this.user.abilities)

        // Refector abilities
        this.user.abilities = this.user.abilities ?? []
        let abilities = this.user.abilities.map(ability => {
          if (
            ability.subject == "dashboard.widget" ||
            ability.subject == "department_dashboard.widget"
          ) {
            return {
              action: 'view',
              subject: `${ability.subject}.${ability.action}`,
            }
          } else {
            return {
              action: ability.action,
              subject: ability.subject,
            }
          }
        })

        // Store and update abilities
        this.user.abilities = abilities
        ability.update(abilities)
      }
    },
    setToken(token) {
      if(token){
        localStorage.setItem('accessToken', token)
        this.token = token
      }else{
        this.token =null
        localStorage.removeItem('accessToken')
      }
    },
    async fetchUser() {
      if(this.token){
        await $api('auth/user').then(({ userData }) => {
          this.setUser(userData)
        }).catch(error => {
          this.setUser(null)
          this.setToken(null)
        })
      }

    },
    async login(credentials) {
      let errors = {
        password: ['Something went wrong'],
      }
      
      return await $api('/auth/login', {
        method: 'POST',
        body: credentials,
      })
        .then(({ userData, accessToken }) => {
          this.setUser(userData)
          this.setToken(accessToken)
          
          return { status: true, ability: this.user.abilities, errors, modules: userData.modules }
        })
        .catch(error => {
          if(error.message){
            errors.password = [error?.data?.message??error.message]
          } 
          
          return { status: false, errors }
        })
      
    },
    async switchUnit(unitId) {
      return await $api('/auth/switch-unit', {
        method: 'POST',
        body: { unitId },
      })
        .then(({ userData, accessToken }) => {
          this.setUser(userData)
          this.setToken(accessToken)
          
          return { status: true }
        })
        .catch(error => {
          return { status: false }
        })
    },
    async subscribeToNotifications() {

      try{

        if (!('serviceWorker' in navigator)) {
          console.log('Service worker not supported')
          
          return
        }
        const registration = await navigator.serviceWorker.ready
  
        const subscription = await registration.pushManager.getSubscription()
        if (!subscription) {
          const payload= registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              import.meta.env.VITE_VAPID_PUBLIC_KEY,
            ),
          })
    
          console.log('Subscribing to notifications')
          console.log(payload)
          
          return payload.then(subscription => {
            return $api('/auth/subscribe', {
              method: 'POST',
              body: subscription,
            })
          })
        }
      }
      catch(error){
        console.log('ServiceWorker registration failed:', error)
      }
    },
    async unsubscribeFromNotifications() {
      if (!('serviceWorker' in navigator)) {
        console.log('Service worker not supported')
        
        return
      }
      const registration = await navigator.serviceWorker.ready

      const subscription = await registration.pushManager.getSubscription()
      if (subscription) {
        console.log('Unsubscribing from notifications')
        
        return subscription.unsubscribe().then(() => {
          return $api('/auth/unsubscribe', {
            method: 'POST',
            body: subscription,
          })
        })
      }
    }

    ,
    async logout() {
      this.unsubscribeFromNotifications()
      $api('/auth/logout', {
        method: 'GET',
      })
      this.setUser(null)
      this.setToken(null)
      localStorage.removeItem('user')
      router.push({ name: 'app-login' })
    },
  },
  computed: {
    user: state => state.user,
    token: state => state.token,
  },
})
