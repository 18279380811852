<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import { VDataTableServer, VDataTable } from 'vuetify/labs/VDataTable'
import ContributoryFactorDrawer from '@/views/incident/master/ContributoryFactorDrawer.vue'
import ContributoryFactorService from '@/services/incident/contributoryFactor.service'
import { factorTypeTitle } from '@/enums/incident/factor.type'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: 'incident-master-contributory-factor',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order
}

// Headers
const headers = [
  { title: "", key: "data-table-expand" },
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Factor Type',
    key: 'factor_type',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: typeListing,
  execute: fetchTypeListing,
} = await useApi(createUrl('/masters/contributory-factors', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
    search: {
      parent_id: 'null',
    },
  },
}))

const types = computed(() => typeListing.value.data)
const totalContributoryFactors = computed(() => typeListing.value?.meta?.total)

const isDrawerVisible = ref(false)
const isViewOnly = ref(false)
const typeData = ref()

const showContributoryFactor = (data = null, viewOnly = false) => {
  typeData.value = data
  isDrawerVisible.value = true
  isViewOnly.value = viewOnly
}

watch(isDrawerVisible, value => {
  if (value === false) {
    typeData.value = null
    isViewOnly.value = false
  }
})

const refConfirmDialog = ref()

const showConfirmDialog = data => {
  typeData.value = data
  isConfirmDialogVisible.value = true
}

const deleteContributoryFactor = async (confirm, data) => {
  if(data?.id && confirm){
    ContributoryFactorService.destroy(
      data.id, 
      () => {
        refConfirmDialog.value.onSuccess()
        isConfirmDialogVisible.value = false
        fetchTypeListing()
      },
      () => {
        refConfirmDialog.value.onError()
      },
    )
  }
}

watch(isConfirmDialogVisible, value => {
  if (value === false) typeData.value = null
})
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <!--
            <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
            >
            Export
            </VBtn> 
          -->
       
          <VBtn
            prepend-icon="tabler-plus"
            @click="showContributoryFactor(null)"
          >
            Add Contributory Factor
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="types"
        :items-length="totalContributoryFactors"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #expanded-row="slotProps">
          <tr class="!text-normal">
            <td
              colspan="12"
              class="level-bg-color px-6 py-6 overflow-hidden"
            >
              <VCard
                variant="outlined w-100"
                class="table-container"
              >
                <VDataTable
                  :items="slotProps.item?.children ?? []"
                  :headers="[
                    { title: 'Name', key: 'name'},
                    { title: 'Actions', key: 'actions'},
                  ]"
                  :items-per-page="-1"
                  hide-default-footer
                  disable-sort
                  class="custom-data-table text-no-wrap overflow-auto"
                >
                  <template #item.name="{ item }">
                    <div
                      class="d-flex align-center cursor-pointer"
                      :questionData="item"
                      @click="showContributoryFactor(item, true)"
                    >
                      <div class="d-flex flex-column">
                        <h6 class="text-base">
                          {{ item.name }}
                        </h6>
                      </div>
                    </div>
                  </template>
                  <template #item.actions="{ item }">
                    <IconBtn @click="showConfirmDialog(item)">
                      <VIcon icon="tabler-trash" />
                    </IconBtn>

                    <IconBtn @click="showContributoryFactor(item)">
                      <VIcon icon="tabler-edit" />
                    </IconBtn>
                  </template>
                </VDataTable>
              </VCard>
            </td>
          </tr>
        </template>

        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :formTypeData="item"
            @click="showContributoryFactor(item, true)"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>

        <template #item.factor_type="{ item }">
          {{ factorTypeTitle(item.factor_type) }}
        </template>  

        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn @click="showConfirmDialog(item)">
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn @click="showContributoryFactor(item)">
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalContributoryFactors) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalContributoryFactors / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalContributoryFactors / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <ContributoryFactorDrawer
      v-model:isDrawerOpen="isDrawerVisible"
      :form-data="typeData"
      :view-only="isViewOnly"
      @refetch-listing="fetchTypeListing"
    />
    <ConfirmDialog
      ref="refConfirmDialog"
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="Contributory Factor Deleted."
      confirm-title="Deleted!"
      cancel-title="Error!"
      cancel-msg="Something Went Wrong."
      :is-confirm-visible="false"
      :is-error-visible="false"
      :data="typeData"
      @confirm="deleteContributoryFactor"
    />
  </section>
</template>

<style>
.level-bg-color {
  background-color: #f7f7f7;
}

.non-selectable {
  user-select: none !important;
}

/* stylelint-disable-next-line no-descending-specificity */
.custom-data-table .v-data-table-footer {
  display: none !important;
}
</style>
