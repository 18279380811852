<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import departmentService from '@/services/department.service'
import DepartmentDrawer from '@/views/masters/department/drawer/DepartmentDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

const searchQuery = ref('')

definePage({
  meta: {
    name: 'master-department',
    subject: 'master.department',
    action: 'read',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const errors = ref({
  name: [],
  code: [],
  status: [],
})

const onError = error => {
  if (error.data){
    errors.value = error.data.errors
  }
}

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'Code',
    key: 'code',
  },
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: departmentsData,
  execute: fetchDepartments,
} =  useApi(createUrl('/masters/departments', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
  },
}))

const departments = computed(() => departmentsData.value?.data)
const totalDepartments = computed(() => departmentsData.value?.meta?.total)

const updateDepartment = data => {
  if (data.id){
    departmentService.updateDepartment(data.id, data, ()=>
    {
      fetchDepartments()
      isDepartmentDrawerVisible.value = false
      resetErrors()
    }, onError)
  }
}

const saveDepartment = data => {
  departmentService.addDepartment(data, ()=>
  {
    fetchDepartments()
    isDepartmentDrawerVisible.value = false
    resetErrors()

  }, onError)
}

const resetErrors = () => {
  errors.value = {
    name: [],
    code: [],
    status: [],
    can_have_escalation: [],
  }
}

const isDepartmentDrawerVisible = ref(false)
const departmentDrawerData = ref()
const departmentDeleteData = ref()
const departmentDrawerType = ref('Add')

const showDepartment = (departmentData, type) => {
  errors.value = {
    name: [],
    code: [],
    status: [],
  }
  departmentDrawerData.value = departmentData
  departmentDrawerType.value = type  
  isDepartmentDrawerVisible.value = true
}

const isConfirmDialogVisible = ref(false)
const refConfirmDialog = ref()

const showConfirmDialog = departmentData => {
  departmentDeleteData.value = departmentData
  isConfirmDialogVisible.value = true
}

const deleteDepartment = async (confirm, data) => {
  if (data?.id && confirm) {
    await departmentService.deleteDepartment(
      data.id, 
      () => {
        refConfirmDialog.value.onSuccess()
        isConfirmDialogVisible.value = false
        fetchDepartments()
      },
      () => {
        refConfirmDialog.value.onError()
      },
    )
  }
}

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value){
    departmentDeleteData.value = {}
  }
})
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

       
          <VBtn
            v-if="$can('create', 'master.department')"
            prepend-icon="tabler-plus"
            @click="showDepartment(null,'Add')"
          >
            Add New Department
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="departments"
        :items-length="totalDepartments"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :departmentData="item"
            @click="showDepartment(item,'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.code="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.code }}</span>
        </template>

    
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn 
            v-if="$can('delete', 'master.department')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn 
            v-if="$can('update', 'master.department')"
            @click="showDepartment(item,'Edit')"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalDepartments) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalDepartments / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalDepartments / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <DepartmentDrawer
      v-model:isDrawerOpen="isDepartmentDrawerVisible"
      :department-data="departmentDrawerData"
      :type="departmentDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="departmentDrawerType = $event"
      @update:department-data="updateDepartment"
      @save-department="saveDepartment"
    />
    <ConfirmDialog
      ref="refConfirmDialog"
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="Department Deleted."
      confirm-title="Deleted!"
      cancel-title="Error!"
      cancel-msg="Something Went Wrong."
      :is-confirm-visible="false"
      :is-error-visible="false"
      :data="departmentDeleteData"
      @confirm="deleteDepartment"
    />
  </section>
</template>
