<script setup>
import TagTable from '@/views/masters/tag/Table.vue'

definePage({
  meta: {
    name: 'feedback-master-tag',
  },  
})
</script>

<template>
  <div>
    <TagTable page-type="FEEDBACK" />
  </div>
</template>
