<script setup>
import { userAuthState } from "@/@core/auth"

import IncidentTable from "@/views/incident/incident-panel/IncidentTable.vue"
import IncidentDrawer from "@/views/incident/incident-panel/IncidentDrawer.vue"
import FilterComponent from "@/@core/components/custom/FilterComponent.vue"
import Graph from "@/views/incident/incident-panel/Graph.vue"

definePage({
  meta: {
    name: "incident-incident",
    subject: "incident.incident",
    action: "read",
  },
})

// const auth = userAuthState()

const filters = ref({})
const { data: filterOptions } = await useApi(createUrl('/incidents/filters'))

const refTable = ref()
const refGraph = ref()

const isArchiveTableDialogVisible = ref(false)
</script>

<template>
  <section>
    <VRow class="match-height mb-6">
      <VCol
        cols="12"
        md="6"
        lg="6"
      >
        <FilterComponent
          v-model:filters="filters"
          :filters-options="filterOptions"
        />
      </VCol>
      <VCol
        cols="12"
        md="6"
        lg="6"
      >
        <VCard>
          <VCardText>
            <Graph 
              ref="refGraph" 
              :filters="filters"
            />
          </VCardText>
        </VCard>
      </VCol>
    </VRow>

    <IncidentTable
      ref="refTable"
      :filters="filters"
      @fetch-graph="() => {
        refGraph.fetchGraphData()
      }"
      @archive-dialog-visible="isArchiveTableDialogVisible = true"
    />

    <!-- Incident Archive -->
    <VDialog v-model="isArchiveTableDialogVisible">
      <DialogCloseBtn
        size="small"
        @click="() => (isArchiveTableDialogVisible = !isArchiveTableDialogVisible)"
      />

      <VCard>
        <VCardText>
          <IncidentTable 
            :filters="filters"
            :config="{
              archiveList: true,
              archiveVisible: false,
              createActionButtonVisible: false,
              tableActionColumnVisible: true,
              deleteActionButtonVisible: true,
            }"
            @fetch-table-listing="() => {
              refTable.fetchIncidents()
            }"
            @fetch-graph="() => {
              refGraph.fetchGraphData()
            }"
          />
        </VCardText>
      </VCard>
    </VDialog>
  </section>
</template>
