<script setup>
import { cookieRef } from '@/@layouts/stores/config'
import IncidentService from '@/services/incident/incident.service'
import { useRoute, useRouter } from 'vue-router'

// Forms
import IncidentDetailsForm from "@/views/incident/incident-panel/forms/IncidentDetails.vue"
import IncidentQuestionsForm from "@/views/incident/incident-panel/forms/IncidentQuestions.vue"
import ReviewForm from "@/views/incident/incident-panel/forms/Review.vue"

definePage({
  meta: {
    name: "incident-collection",
    subject: 'incident.incident',
    action: 'create',
  },
})

const route = useRoute()
const router = useRouter()

const snackbar = useToast()

const unitId = ref(cookieRef('selectedUnit') ?? null)

const currentStep = ref(0)
const isCurrentStepValid = ref(true)

const steps = [
  {
    title: "Incident Details",
  },

  // {
  //   title: "Involved Parties",
  // },
  {
    title: "Incident Questions",
  },
  {
    title: "Review & Submit",
  },
]

const refForm = ref()
const incidentDetailsForm = ref({})
const refIncidentQuestionsForm = ref()
const incidentQuestionsForm = ref({})

// const refIncidentDetailsForm = ref()
// const involvedPartiesForm = ref({})

// const updateIncidentData = data => {
//   //
// }

const incidentFormResetValidation = () => {
  // refIncidentDetailsForm.value?.resetValidation()
  refIncidentQuestionsForm.value?.resetValidation()
}

if (route.params.id && route.params.id !== 'entry') {
  await useApi(createUrl(`/incidents/incidents/${route.params.id}`, {}), {
    afterFetch: response => {
      const { 
        // involved_parties,
        incident_question_answers,
        ...otherDetails
      } = response.data

      incidentDetailsForm.value = {
        // new_attachments: [],
        ...otherDetails,
      }

      // involvedPartiesForm.value = {
      //   involved_parties: involved_parties
      // }

      incidentQuestionsForm.value = {
        incident_category_id: otherDetails.incident_category_id,
        incident_sub_category_id: otherDetails.incident_sub_category_id,
        incident_question_answers: incident_question_answers,
      }
    },
    onFetchError: error => {
      // console.log(error)
      // router.push({
      //   name: 'invalid-url',
      //   params: { code: 404 } 
      // })
    },
  })
}

const validateForm = () => {
  refForm.value?.validate().then(({ valid, errors }) => {
    console.log(errors)
    if (valid) {
      // currentStep.value++;
      isCurrentStepValid.value = true
      nextTick(() => {
        refForm.value?.resetValidation()
      })
    } else {
      isCurrentStepValid.value = false
    }
  })
}

const submitIncidentDetailsForm = async formData => {

  // Field validation
  // const validate = await refIncidentDetailsForm.value.validate().then(({ valid, errors }) => {
  //   console.log(valid, errors)
    
  //   return valid
  // })

  // if (validate === false) {
  //   return
  // }

  const {
    self_reported,
    ...refactorFormData
  } = structuredClone(toRaw(formData))

  if (matchObject(refactorFormData, incidentDetailsForm.value) === false) {
    let formattedToFormData
    if (route.params.id) {
      formattedToFormData = convertToFormData({
        step: 1,
        unit_id: unitId.value,
        incident_id: route.params.id,
        ...refactorFormData,
      })
    } else {
      formattedToFormData = convertToFormData({
        step: 1,
        unit_id: unitId.value,
        ...refactorFormData,
      })
    }

    // Remove all the null values
    const restructureFormData = new FormData()
    for (const [key, value] of formattedToFormData.entries()) {
      if (value != 'null') restructureFormData.append(key, value)
    }

    await $api('/incidents/incidents', {
      method: 'POST',
      headers: {
        ContentType: 'multipart/form-data',
      },
      body: restructureFormData,
    }).then(({ data }) => {

      // Update values
      // incidentQuestionsForm.value.incident_category_id = data.incident_category_id
      // incidentQuestionsForm.value.incident_sub_category_id = data.incident_sub_category_id

      // Update data
      const { 
        incident_question_answers,
        ...otherDetails
      } = data

      incidentDetailsForm.value = otherDetails
      incidentQuestionsForm.value = {
        incident_category_id: otherDetails.incident_category_id,
        incident_sub_category_id: otherDetails.incident_sub_category_id,
        incident_question_answers,
      }

      if (!route.params.id) {
        router.push({
          name: 'app-incident-entry',
          params: { id: data.id }, 
        })
      }

      // Move to next step
      currentStep.value++
      snackbar.success('Incident saved.', 'success')
      
    }).catch(error => {
      // currentStep.value = 0
      console.error(error)
      console.error('Error adding incident:', error)
    })
  } else {
    currentStep.value++
  }

}

// const submitInvolvedPartiesForm = async (formData) => {
//   const refectorFormData = {involved_parties: []}

//   refectorFormData.involved_parties = formData.involved_parties.filter(item => (
//     item.role_in_incident !== null &&
//     item.name !== null &&
//     item.contact_number !== null
//   ))

//   if (matchObject(refectorFormData, involvedPartiesForm.value) === false) {
//     await $api('/incidents/incidents', {
//       method: 'POST',
//       headers: {
//         ContentType: 'multipart/form-data',
//       },
//       body: { 
//         step: 2, 
//         incident_id: route.params.id,
//         ...formData,
//       },
//     }).then(({ data }) => {
//       snackbar.success('Incident saved.', 'success')
//     }).catch(error => {
//       console.error(error)
//     })
//   }
// }

const submitIncidentQuestionsForm = async formData => {

  // Field validation
  const validate = await refIncidentQuestionsForm.value.validate().then(({ valid, errors }) => {    
    return valid
  })

  if (validate === false) {
    return
  }

  // Refactor data
  formData.incident_question_answers = formData.incident_question_answers.map(item => {
    if (typeof item.response == 'object') {
      if (item.question_type == 'select') {
        item.incident_question_option_id = item.response.id
        item.response = item.response.label
      }
      if (item.question_type == 'radio') {
        item.incident_question_option_id = item.response.value
        item.response = item.response.label
      }
    }
    
    return {
      ...item,
      incident_question_id: item.question_id,
    }
  })

  // const {
  //   incident_category_id,
  //   incident_sub_category_id,
  //   ...compareData
  // } = incidentQuestionsForm.value
  // matchObject(formData, compareData) === false

  if (true) {
    await $api('/incidents/incidents', {
      method: 'POST',
      headers: {
        ContentType: 'multipart/form-data',
      },
      body: { 
        step: 2, 
        incident_id: route.params.id,
        ...formData,
      },
    }).then(({ data }) => {

      // Update data
      const { 
        incident_question_answers,
        ...otherDetails
      } = data

      incidentDetailsForm.value = otherDetails
      incidentQuestionsForm.value = {
        incident_category_id: otherDetails.incident_category_id,
        incident_sub_category_id: otherDetails.incident_sub_category_id,
        incident_question_answers,
      }

      currentStep.value++
      snackbar.success('Incident saved.', 'success')
    }).catch(error => {
      console.error(error)
      console.error('Error adding incident:', error)
    })
  } else {
    currentStep.value++
  }

}

const updateCurrentStep = value => {
  currentStep.value = value
}

const submitReviewForm = () => {

  IncidentService.create({
    step: 3,
    incident_id: route.params.id,
  }, () => {
    router.push({
      name: 'app-incident-incident',
    })
  }, () => {})

}

// const incidentForm = computed(() => {
//   console.log('UPDATE')
//   return {
//     ...incidentDetailsForm.value,
//     ...incidentQuestionsForm.value,
//   }
// })
</script>

<template>
  <!-- 👉 Stepper -->
  <div class="mb-6">
    <AppStepper
      v-model:current-step="currentStep"
      :items="steps"
      :is-active-step-valid="isCurrentStepValid"
    />
  </div>

  <!-- 👉 stepper content -->
  <VWindow
    v-model="currentStep"
    class="disable-tab-transition"
  >
    <VWindowItem class="bg-var-theme-white">
      <IncidentDetailsForm
        :unit-id="unitId"
        :data="incidentDetailsForm"
        :current-step="currentStep"
        @update:form-data="submitIncidentDetailsForm"
        @update:current-step="updateCurrentStep"
        @update:reset-validation="incidentFormResetValidation"
      />
    </VWindowItem>

    <VWindowItem class="bg-var-theme-white">
      <VForm ref="refIncidentQuestionsForm">
        <IncidentQuestionsForm
          :data="incidentQuestionsForm"
          :current-step="currentStep"
          @update:form-data="submitIncidentQuestionsForm"
          @update:current-step="updateCurrentStep"
        />
      </VForm>
    </VWindowItem>

    <VWindowItem class="bg-var-theme-white">
      <VForm
        ref="refForm"
        @submit.prevent="validateForm"
      >
        <ReviewForm
          :data="{
            ...incidentDetailsForm,
            ...incidentQuestionsForm,
          }"
          :current-step="currentStep"
          @update:submit="submitReviewForm"
          @update:current-step="updateCurrentStep"
        />
      </VForm>
    </VWindowItem>
  </VWindow>
</template>
