<script setup>
import { cookieRef } from '@/@layouts/stores/config'
import { IncidentStatus } from '@/enums/incident/incident.status'
import { RejectionType, rejectionTypeTitle, rejectionTypeColor } from '@/enums/incident/rejection.type'
import { RcaStatus, rcaStatusColor } from '@/enums/incident/rca.status'
import IncidentService from '@/services/incident/incident.service'
import { userAuthState } from '@core/auth'
import { useRoute, useRouter } from 'vue-router'

import RcaDrawer from '@/views/incident/incident-panel/Rca.vue'
import IncidentDetailsView from "@/views/incident/incident-panel/views/Details.vue"
import InvolvedPartiesView from "@/views/incident/incident-panel/views/InvolvedParties.vue"
import IncidentQuestionsView from "@/views/incident/incident-panel/views/Questions.vue"
import IncidentAttachmentsView from "@/views/incident/incident-panel/views/Attachments.vue"

import { PerfectScrollbar } from "vue3-perfect-scrollbar"

import { titleCase } from '@/@core/utils/formatters'
import { watch } from 'vue'
import { VRadio } from 'vuetify/lib/components/index.mjs'

definePage({
  meta: {
    name: "incident-view",
    subject: "incident.incident",
    action: "read",
  },
})

const route = useRoute()
const router = useRouter()

const unitId = ref(cookieRef('selectedUnit') ?? null)

const incident = ref(null)
const auth = userAuthState()

await useApi(createUrl(`/incidents/incidents/${route.params.id}`, {}), {
  afterFetch: ({ data }) => {
    incident.value = data
  },
  onFetchError: error => {
    router.push({
      name: 'invalid-url',
      params: { code: 404 }, 
    })
  },
})

const isAuthUserIsReporter = computed(() => {
  return incident.value?.reported_by_id === auth?.user?.id
})

const {
  data: rcaListing,
  execute: fetchRcaListing,
} = await useApi(createUrl('incidents/incident-rcas', {
  query: {
    search: {
      incident_id: incident.value?.id,
    },
  },
}))

const refetchIncident = async () => {
  useApi(createUrl(`/incidents/incidents/${route.params.id}`, {}), {
    afterFetch: ({ data }) => {
      incident.value = data
    },
  })
  fetchRcaListing()
}

// Perfect scrollbar
const chatLogPS = ref()

const scrollToBottomInChatLog = () => {
  const scrollEl = chatLogPS.value.$el || chatLogPS.value

  scrollEl.scrollTop = scrollEl.scrollHeight
}

const rca = ref()
const isRcaDrawer = ref(false)

watch(isRcaDrawer, value => {
  if (value === false) rca.value = null
}, { deep: true })

const isApprovalDialog = ref(false)
const approvalForm = ref({})

const {
  data: typeOfIncidentOptions,
} = await useApi(createUrl('/masters/incident-types', {}))

const {
  data: classificationOptions,
} = await useApi(createUrl('/masters/tags', {
  query: {
    search: {
      unit_id: unitId.value,
      tag_type: 'EVENT_CLASSIFICATION',
    },
  },
}))

const submitApproval = async () => {
  await IncidentService.approve(
    route.params.id, 
    {
      unit_id: unitId.value,
      ...approvalForm.value,
    },
    data => {
      //Update data
      incident.value.incident_status = data.incident_status
      incident.value.incident_type = data.incident_type
      incident.value.event_classification = data.event_classification

      isApprovalDialog.value = false
    },
    error => {
      //
    },
  )
}

const isRejectionDialog = ref(false)
const rejectionForm = ref({})
const rejectionTypeOptions = await getEnumValue('RejectionTypes')

const submitRejectionForm = async () => {

  await IncidentService.reject(
    route.params.id, 
    {
      unit_id: unitId.value,
      ...rejectionForm.value,
    },
    data => {
      //Update data
      incident.value.incident_status = data.incident_status
      incident.value.rejection_type = data.rejection_type
      incident.value.rejection_note = data.rejection_note
      
      isRejectionDialog.value = false
    },
    error => {
      //
    },
  )
}

const review = async () => {
  await IncidentService.review(route.params.id, {
    unit_id: unitId.value,
    incident_status: IncidentStatus.DRAFT,
  }, data => {
    //Update data
    incident.value.incident_status = data.incident_status

    // Redirection
    router.push({
      name: 'app-incident-entry',
      params: { id: incident.value.id },
    })
  }, error => {
    //
  },
  )
}
</script>

<template>
  <div class="mt-4">
    <VContainer fluid>
      <VRow>
        <VCol
          cols="12"
          md="8"
        >
          <div class="d-md-flex align-center justify-space-between">
            <div class="text-overline">
              <h1>{{ incident.code }}</h1>
            </div>
            <!-- sub title -->
            <div class="text-body-1 off">
              <b>Incident Datetime:</b>
              {{ incident.incident_datetime }}
            </div>
          </div>
        </VCol>
      </VRow>
      <VRow justify="space-around">
        <VCol
          
          md="8"
          sm="12"
          order="1"
        >
          <IncidentDetailsView :data="incident" />
          <InvolvedPartiesView :data="incident" />
          <IncidentQuestionsView :data="incident" />
          <IncidentAttachmentsView 
            v-if="incident.attachments.length > 0" 
            class="mt-6" 
            :data="incident"
          />
        </VCol>
  
      
        <VCol
          cols="12"
          md="4"
          order-sm="1"
        >
          <div class="pl-md-2 pl-sm-0">
            <!-- status chip -->
            <div class="text-h5 d-none d-sm-flex mb-5">
              <VChip
                class="pa-2 "
                variant="elevated"
                :color="resolveIncidentStatusColor(incident.incident_status)"
                size="20"
              >
                <div class="px-15">
                  {{ titleCase(incident.incident_status) }}
                </div>
              </VChip>
            </div>

            <!-- edit card -->
            <VCard
              v-if="incident.incident_status === IncidentStatus.DRAFT"
              class="mb-5"
            >
              <VCardItem>
                <div>
                  Your Incident Report is not yet submitted, click on below button to resume incident reporting request.
                </div>
                  
                <VRow class="mt-2">
                  <VCol>
                    <VBtn 
                      color="primary"
                      block
                      @click="() => {
                        router.push({
                          name: 'app-incident-entry',
                          params: { id: incident.id },
                        })
                      }"
                    >
                      Resume Incident Report
                    </VBtn>
                  </VCol>
                </VRow>
              </VCardItem>
            </VCard>
            <!-- edit card close -->

            <!-- rejected & redraft -->
            <VCard
              v-if="incident.incident_status === IncidentStatus.REJECTED || incident.incident_status === IncidentStatus.REQUEST_SUBMITTED "
              class="mb-5"
            >
              <VCardItem v-if="incident.incident_status === IncidentStatus.REJECTED">
                <div v-if="incident.rejection_type === RejectionType.NOT_AN_INCIDENT">
                  The incident didn't matched the quality criteria.
                </div>
                <div v-else>
                  The incident didn't matched the quality criteria please review the changes requested by quality team.
                </div>
                <VRow class="mt-2">
                  <VCol cols="4">
                    Rejection Type:
                  </VCol>
                  <VCol cols="8">
                    <VChip
                      class="text-h6 pa-2"
                      size="30"
                      :color="rejectionTypeColor(incident.rejection_type)"
                    >
                      {{ rejectionTypeTitle(incident.rejection_type) }}
                    </VChip>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="4">
                    Rejection Remarks:
                  </VCol>
                  <VCol cols="8">
                    {{ incident.rejection_note }}
                  </VCol>
                </VRow>
                <VRow v-if="incident.rejection_type !== RejectionType.NOT_AN_INCIDENT">
                  <VCol>
                    <VBtn 
                      v-if="$can('update', 'incident.incident') && incident.incident_status === IncidentStatus.REJECTED"
                      color="primary"
                      block
                      @click="review"
                    >
                      Review & Draft
                    </VBtn>
                  </VCol>
                </VRow>
              </VCardItem>
              <VCardItem 
                v-if="$can('update', 'incident.incident')
                  && isAuthUserIsReporter 
                  && incident.incident_status === IncidentStatus.REQUEST_SUBMITTED"
              >
                <div class="text-h4">
                  Update & Resubmit
                </div>
                <div>
                  Want to Update the Incident?
                </div>
                  
                <VRow class="mt-2">
                  <VCol>
                    <VBtn 
                      color="primary"
                      block
                      @click="review"
                    >
                      Review & Draft
                    </VBtn>
                  </VCol>
                </VRow>
              </VCardItem>
            </VCard>
            <!-- rejection redraft close -->
               
            <!-- Approval or reject -->
            <VCard
              v-if="$can('verification', 'incident.incident') &&
                incident.incident_status === IncidentStatus.REQUEST_SUBMITTED
              "
            >
              <VCardItem>
                <div class="text-h4">
                  Approval
                </div>
                <div>
                  You have the option to approve the incident, reject it, or engage in a
                  discussion.
                </div>
              </VCardItem>
  
              <VCardText 
                v-if="$can('verification', 'incident.incident')"
                class="mt-2"
              >
                <VRow>
                  <VCol
                    v-if="incident.incident_status === IncidentStatus.REQUEST_SUBMITTED"
                    cols="6"
                  >
                    <VBtn
                      color="primary"
                      block
                      @click="isApprovalDialog = true"
                    >
                      Approve
                    </VBtn>
                  </VCol>
                  <VCol
                    v-if="incident.incident_status === IncidentStatus.REQUEST_SUBMITTED"
                    cols="6"
                  >
                    <VBtn
                      block
                      color="error"
                      @click="isRejectionDialog = true"
                    >
                      Reject
                    </VBtn>
                  </VCol>
                </VRow>
              </VCardText>
            </VCard>
  
            <!-- delegation -->
            <VCard
              v-else-if="
                incident.incident_status === IncidentStatus.DELEGATION_PENDING ||
                  incident.incident_status === IncidentStatus.RCA_PENDING
              "
            >
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VList>
                      <VListItem>
                        <template #prepend>
                          <div class="text-h6">
                            Incident Type:
                          </div>
                        </template>
                        <VListItemTitle>
                          <div>
                            <VChip
                              class="pa-2 ml-3"
                              size="medium"
                              :color="incident.incident_type?.color"
                            >
                              <VIcon
                                start
                                :icon="incident.incident_type?.icon"
                              />
                              {{ incident.incident_type?.name }}
                            </VChip>
                          </div>
                        </VListItemTitle>
                      </VListItem>

                      <VListItem>
                        <VListItemTitle class="text-wrap mt-2">
                          <div class="text-h6">
                            Event Classification
                          </div>
                          <VAlert
                            border
                            class="text-wrap mt-2"
                          >
                            {{ incident.event_classification?.name }}
                          </VAlert>
                        </VListItemTitle>
                      </VListItem>
                    </VList>
                  </VCol>
                </VRow>
              
                <VRow v-if="$can('create', 'incident.incident_rca')">
                  <VCol>
                    <VBtn 
                      block
                      @click="isRcaDrawer = true"
                    >
                      Delegate
                    </VBtn>
                  </VCol>
                </VRow>
              </VCardText>
            </VCard>
  
            <!-- RCA Listing -->
            <VCard 
              v-if="incident.incident_status !== IncidentStatus.DRAFT &&
                incident.incident_status !== IncidentStatus.REQUEST_SUBMITTED &&
                incident.incident_status !== IncidentStatus.REJECTED
              "
              class="mt-6"
            >
              <VCardText v-if="rcaListing?.length">
                <div class=" mb-4 d-md-flex  align-center">
                  <div class="text-h4 flex-1-0 pr-5">
                    Investigation List
                  </div>
                
                  <VDivider thickness="2" />
                </div>
               
                <PerfectScrollbar 
                  :options="{ wheelPropagation: false }"
                  style="block-size: 420px !important; padding-block-end: 20px;"  
                >
                  <div class="d-flex flex-column">
                    <div
                      v-for="(item, index) in rcaListing"
                      :key="index"
                    >
                      <div>
                        <div class="d-flex align-center justify-space-between">
                          <div>
                            <div class="text-h6">
                              {{ item.title }}
                            </div>
                            <div class="text-body-1 text-grey">
                              {{ formatDate(item.created_at) }}
                            </div>
                          </div>
                          <div class="d-flex gap-x-4">
                            <VChip
                              class="pa-2"
                              size="small"
                              :color="rcaStatusColor(item.rca_status)"
                            >
                              {{ titleCase(item.rca_status) }}
                            </VChip>
                            <!--
                              <VBtn 
                              v-if="$can('update', 'incident.incident_rca') 
                              
                              && (
                              item.rca_status === RcaStatus.PENDING 
                              || item.rca_status === RcaStatus.RE_OPENED
                              || item.rca_status === RcaStatus.DRAFT
                              )
                                
                              "
                              color="primary"
                              variant="text"
                              @click="() => {
                              rca = item
                              isRcaDrawer = true
                              }"
                              >
                              <VIcon 
                              size="20"
                              icon="tabler-edit"
                              />
                              </VBtn> 
                            -->
                          </div>
                        </div>
                        <!-- users & group -->
                    
                        <div class="mt-6">
                          <div>
                            <p class="text-h6 mb-4">
                              Investigators (Individuals & Groups) For Delegate
                            </p>
                              
                            <VRow>
                              <!-- users -->
                              <VCol
                                v-if="item?.user_delegates?.length"
                                cols="12"
                              >
                                <div class="d-flex flex-wrap gap-4">
                                  <div 
                                    v-for="userDelegate in item?.user_delegates"
                                    :key="userDelegate?.id"
                                    class="p-4"
                                  >
                                    <VChip
                                      class="rounded-pill"
                                      style="block-size: 44px !important;"
                                    >
                                      <VAvatar
                                        color="primary"
                                        class="text-white me-2"
                                        size="32"
                                      >
                                        {{ userDelegate?.delegate?.name.charAt(0) }}
                                      </VAvatar>
                                      <div class="me-2">
                                        <VListItemTitle class="text-subtitle-2">
                                          {{ userDelegate?.delegate?.name }}
                                        </VListItemTitle>
                                        <VListItemSubtitle>
                                          {{ userDelegate?.delegate?.employee_code }}
                                        </VListItemSubtitle>
                                      </div>
                                    </VChip>
                                  </div>
                                </div>
                              </VCol>
                              <!-- users end -->

                              <!-- groups -->
                              <VCol
                                v-if="item?.group_delegates?.length > 0"
                                cols="12"
                              >
                                <div class="d-flex flex-column gap-4">
                                  <div
                                    v-for="groupDelegate in item?.group_delegates"
                                    :key="groupDelegate?.id"
                                  >
                                    <div class="">
                                      {{ groupDelegate.delegate.name }}
                                    </div>
                                    <VDivider
                                      class="mt-1"
                                      thickness="1"
                                    />
                                    <div class="mt-4 d-flex flex-wrap gap-4">
                                      <div 
                                        v-for="user in groupDelegate.delegate.users"
                                        :key="user?.id"
                                        class="p-4"
                                      >
                                        <VChip
                                          class="rounded-pill"
                                          style="block-size: 44px !important;"
                                        >
                                          <VAvatar
                                            color="primary"
                                            class="text-white me-2"
                                            size="32"
                                          >
                                            {{ user?.name.charAt(0) }}
                                          </VAvatar>
                                          <div class="me-2">
                                            <VListItemTitle class="text-subtitle-2">
                                              {{ user?.name }}
                                            </VListItemTitle>
                                            <VListItemSubtitle>
                                              {{ user?.employee_code }}
                                            </VListItemSubtitle>
                                          </div>
                                        </VChip>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </VCol>
                              <!-- groups end -->
                            </VRow>
                          </div>
                        </div>
                        <!-- end users & group -->

                        <!-- note to delege info -->
                        <!--
                          <VAlert
                          class="mt-4"
                          density="compact"
                          color="warning"
                          variant="tonal"
                          >
                          <div class="text-h6 font-weight-bold text-info">
                          Note to Delegates
                          </div>
                          <p>
                          {{ item.delegation_notes }}
                          </p>
                          </VAlert> 
                        -->

                        <div class="mt-6">
                          <div class="text-h6">
                            Note To Delegates
                          </div>
                          <span>
                            {{ item.delegation_notes }}
                          </span>
                        </div>

                        <!-- action buttons -->
                        <div class="mt-6 d-flex flex-wrap gap-4">
                          <VBtn 
                            v-if="$can('update', 'incident.incident_rca') 
                              && (
                                item.rca_status === RcaStatus.PENDING 
                                || item.rca_status === RcaStatus.RE_OPENED
                                || item.rca_status === RcaStatus.DRAFT
                              )
                            "
                            class="flex-1-0"
                            color="primary"
                            variant="tonal"
                            size="small"
                            @click="() => {
                              rca = item
                              isRcaDrawer = true
                            }"
                          >
                            <VIcon 
                              size="20"
                              icon="tabler-edit"
                            />
                          </VBtn>
                          <VBtn 
                            v-if="$can('read', 'incident.incident_rca')"
                            color="primary"
                            variant="tonal"
                            class="flex-1-0"
                            size="small"
                            @click="router.push({ 
                              name: 'app-incident-rca-view',
                              params: { id: item.id } 
                            })"
                          >
                            <VIcon 
                              size="20"
                              icon="tabler-eye"
                            />
                          </VBtn>
                        </div>
                        <!-- action button end -->
                        
                        <VDivider
                          v-if="rcaListing.length - 1 !== index"
                          class="mt-6 mb-6"
                        />
                      </div>
                    </div>
                  </div>
                </PerfectScrollbar>
              </VCardText>
            </VCard>

            <!-- Reporter Details -->
            <VCard class="mt-5">
              <div class="d-md-flex  align-center pa-5">
                <div class="text-h6 flex-1-0 pr-5">
                  Reported User Details
                </div>
              
                <VDivider thickness="2" />
              </div>
 
              <VCardText class="mt-0 pt-0">
                <!--
                  <div class="d-flex">
                  <VAvatar
                  color="primary"
                  class="me-2"
                  size="40"
                  >
                  {{ incident.reported_by?.name.charAt(0) }}
                  </VAvatar>
                  <div>
                  <div class="text-h6">
                  {{ incident.reported_by?.name }}
                  </div>
                  <div class="">
                  {{ incident.reported_by?.employee_code }}
                  </div>
                  </div>
                  </div> 
                -->

                <!--
                  <CardTableLayout
                  striped
                  :items="[
                  { label: 'Code', value: incident.reported_by?.employee_code },
                  { label: 'Name', value: incident.reported_by?.name },
                  { label: 'Contact Number', value: incident.reported_by?.phone },
                  { label: 'Email', value: incident.reported_by?.email },
                  ]"
                  /> 
                -->
                

                <div class="d-flex flex-column gap-2">
                  <div
                    v-if="incident.reported_by?.employee_code || incident.reported_user_employee_code"
                    class="mb-2"
                  >
                    <VLabel class="mb-1 ml-1">
                      Employee Code
                    </VLabel><br>
                    <VChip
                      size="20"
                      class="pa-2 block-chip justify-items-start"
                      :label="false"
                      color="primary"
                      style="justify-content: start !important;"
                    >
                      <span class="ml-2">
                        {{ incident.reported_by?.employee_code ?? incident.reported_user_employee_code }}
                      </span>
                    </VChip>
                  </div>
                  <div
                    v-if="incident.reported_by?.name || incident.reported_user_name"
                    class="mb-2"
                  >
                    <VLabel class="mb-1 ml-1">
                      Name
                    </VLabel><br>
                    <VChip
                      size="20"
                      class="pa-2 block-chip justify-items-start"
                      :label="false"
                      color="primary"
                      style="justify-content: start !important;"
                    >
                      <span class="ml-2">
                        {{ incident.reported_by?.name ?? incident.reported_user_name }}
                      </span>
                    </VChip>
                  </div>
                  <div
                    v-if="incident.reported_by?.email"
                    class="mb-2"
                  >
                    <VLabel class="mb-1 ml-1">
                      Email
                    </VLabel><br>
                    <VChip
                      size="20"
                      class="pa-2 block-chip justify-items-start"
                      :label="false"
                      color="primary"
                      style="justify-content: start !important;"
                    >
                      <span class="ml-2">
                        {{ incident.reported_by?.email }}
                      </span>
                    </VChip>
                  </div>
                  <div
                    v-if="incident.reporter?.phone || incident.reported_user_mobile"
                    class="mb-2"
                  >
                    <VLabel class="mb-1 ml-1">
                      Contact Number
                    </VLabel><br>
                    <VChip
                      size="20"
                      class="pa-2 block-chip justify-items-start"
                      :label="false"
                      color="primary"
                      style="justify-content: start !important;"
                    >
                      <span class="ml-2">
                        {{ incident.reported_by?.phone ?? incident.reported_user_mobile }}
                      </span>
                    </VChip>
                  </div>
                </div>
              </VCardText>
            </VCard>
            <!-- end reporter details -->

            <!-- Incident activites -->
            <VCard class="mt-5">
              <div class="d-md-flex  align-center pa-5">
                <div class="text-h6 flex-1-0 pr-5">
                  Incident Activities
                </div>
              
                <VDivider thickness="2" />
              </div>
 
              <!--
                <VCardText class="mt-0 pt-0">
                <div>
                <VTimeline>
                <VTimelineItem
                v-for="(activity, index) in incident.activities"
                :key="index"
                >
                <VTimelineItemIcon>
                <VIcon
                :color="activity.color"
                :icon="activity.icon"
                />
                </VTimelineItemIcon>
                <VTimelineItemContent>
                <div class="text-h6">
                {{ activity.title }}
                </div>
                <div class="text-body-1">
                {{ activity.description }}
                </div>
                <div class="text-body-2">
                {{ formatDate(activity.created_at) }}
                </div>
                </VTimelineItemContent>
                </VTimelineItem>
                </VTimeline>
                </div>
                </VCardText> 
              -->

              <VCardText class="mt-0 pt-0">
                <PerfectScrollbar 
                  :options="{ wheelPropagation: false }"
                  style="block-size: 320px !important;"  
                >
                  <VTimeline
                    side="end"
                    align="start"
                    line-inset="8"
                    truncate-line="start"
                    density="compact"
                  >
                    <!-- Activity Item -->
                    <VTimelineItem
                      v-for="(activity, index) in incident.activities"
                      :key="index"
                      :dot-color="randomColor()"
                      size="x-small"
                    >
                      <!-- 👉 Header -->
                      <div class="d-flex justify-space-between align-center gap-2 flex-wrap mb-2">
                        <span class="app-timeline-title">
                          {{ activity.description }}
                        </span>
                        <span class="app-timeline-meta">
                          {{ formatDate(activity.created_at) }}
                        </span>
                      </div>

                      <div class="d-flex justify-space-between align-center flex-wrap">
                        <!-- 👉 Avatar & Personal Info -->
                        <div class="d-flex align-center mt-2">
                          <VAvatar
                            size="32"
                            class="me-2"
                            :color="$vuetify.theme.current.dark ? '#f6f6f7' : '#4A5072'"
                          >
                            <span>
                              {{ avatarText(activity.causer.name) }}
                            </span>
                          </VAvatar>
                          <div class="d-flex flex-column">
                            <p class="text-sm font-weight-medium text-medium-emphasis mb-0">
                              {{ activity.causer.name }}
                            </p>
                            <span class="text-sm">
                              {{ activity.causer.employee_code }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </VTimelineItem>
                    <!-- !SECTION -->                
                  </VTimeline>
                </PerfectScrollbar>
              </VCardText>
            </VCard>
          </div>
  
          <!--
            <VCard class="mt-6">
            <ChatBox />
            </VCard> 
          -->
        </VCol>
      </VRow>
    </VContainer>
  </div>

  <RcaDrawer
    v-model:isDrawerOpen="isRcaDrawer"
    :incident="incident"
    :rca="rca"
    @fetch-rca-listing="refetchIncident"
  />

  <!-- Approval Dialog -->
  <VDialog
    v-model="isApprovalDialog"
    max-width="800"
  >
    <template #default="{ }">
      <VCard title="Approval Form">
        <VCardText>
          <div class="d-flex flex-column gap-4">
            <!--
              <AppSelect
              v-model="approvalForm.incident_type_id"
              :items="typeOfIncidentOptions"
              :item-title="(item) => item.name"
              :item-value="(item) => item.id"
              placeholder="Select Type"
              label="Type of Incident"
              :return-object="false"
              :required="true"
              /> 
            -->

            <div>
              <VLabel
                for="incident_type_id"
                text="Type of Incident"
              >
                <span class="text-error">*</span>
              </VLabel>
              <CustomRadiosWithIcon
                v-model:selected-radio="approvalForm.incident_type_id"
                class="my-2"
                hide-radio
                :radio-content="typeOfIncidentOptions"
                :keys="{
                  value: 'id',
                  title: 'name',
                  desc: 'description',
                  icon: 'icon',
                }"
                :grid-column="{ cols: '4', md: '6', sm: '2', xs: '6' }"
                :rules="[requiredValidator]"
              />
            </div>

            <!--
              <AppSelect
              v-model="approvalForm.event_classification_id"
              :items="classificationOptions"
              :item-title="(item) => item.name"
              :item-value="(item) => item.id"
              placeholder="Select Option"
              label="Classification"
              :return-object="false"
              :required="true"
              /> 
            -->

            <div>
              <VLabel
                for="event_classification_id"
                text="Classification"
              >
                <span class="text-error">*</span>
              </VLabel>
              <VRadioGroup
                v-model="approvalForm.event_classification_id"
                :inline="false"
              >
                <VRadio 
                  v-for="(option, index) in classificationOptions"
                  :key="index"
                  :label="option.name"
                  :value="option.id"
                />
              </VRadioGroup>
            </div>
          </div>

          <div class="d-flex justify-end gap-4 mt-6">
            <VBtn
              variant="tonal"
              color="error"
              @click="isApprovalDialog = false"
            >
              Cancel
            </VBtn>
            <VBtn
              variant="tonal"
              @click="submitApproval"
            >
              Approve
            </VBtn>
          </div>
        </VCardText>
      </VCard>
    </template>
  </VDialog>

  <!-- Rejection Form -->
  <VDialog
    v-model="isRejectionDialog"
    max-width="500"
  >
    <template #default="{ }">
      <VCard title="Rejection Form">
        <VCardText>
          <div class="d-flex flex-column gap-4">
            <AppSelect
              v-model="rejectionForm.rejection_type"
              :items="rejectionTypeOptions"
              :item-title="(item) => item.title"
              :item-value="(item) => item.value"
              placeholder="Select Type"
              label="Rejection Type"
              :return-object="false"
              required
            />

            <AppTextarea
              v-model="rejectionForm.rejection_note"
              rows="4"
              label="Note"
              placeholder="Write reason for rejection"
              :return-object="false"
              required
            />
          </div>

          <div class="d-flex justify-end gap-4 mt-6">
            <VBtn
              variant="tonal"
              color="error"
              @click="isRejectionDialog = false"
            >
              Cancel
            </VBtn>
            <VBtn
              variant="tonal"
              @click="submitRejectionForm"
            >
              Reject
            </VBtn>
          </div>
        </VCardText>
      </VCard>
    </template>
  </VDialog>
</template>
